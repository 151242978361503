<script>
import { layoutComputed } from "@/state/helpers";
import CustomSideBar from "../../components/CustomSideBar";

/**
 * Vertical layout
 */
export default {
  components: { CustomSideBar },
  data() {
    return {
      isMenuCondensed: false,
    };
  },
  computed: {
    ...layoutComputed,
  },
}
</script>

<template>
  <div>
    <div id="layout-wrapper">
      <CustomSideBar/>
      <div class="main-content">
        <div class="page-content">
          <div class="container-fluid">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style>
.close-menu {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 3;
  display: none;
}

@media (max-width: 991px) {
  body.sidebar-enable .close-menu {
    display: block;
  }
}
</style>
