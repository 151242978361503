<template>
  <div style="position: relative" v-click-outside="onClickOutside">
    <div class="sidebar" :class="{'show-sidebar': activeSidebar}">
      <div class="sidebar-logo">
        <a href="#" @click.prevent="$router.push({name: 'feed'})">
          <img src="@/assets/images/bimi-logo-white.png" alt="">
        </a>
      </div>
      <div class="sidebar-nav">
        <ul>
          <li class="sidebar-nav__item" v-for="item in getMenuItems" :key="item.id">
            <a href="" v-if="!hasItems(item)" @click.prevent="setActiveMenu(item)" :class="{'active': item.id === activeMenuId}"> <i :class="'bx ' + item.icon"></i> {{ item.label }}</a>
            <div class="w-100" v-if="hasItems(item)">
              <div class="nav-collapse w-100" :class="{'show': (item.id === activeMenuId) || item.subItems.some(e => e.id === activeMenuId)}">
                <div class="nav-collapse__drop" @click.prevent="setActiveMenu(item)">
                  <a href="#">
                    <i :class="'bx '+ item.icon"></i>{{ item.label }}
                  </a>
                  <button v-b-toggle.collapse-1>
                    <i class='bx bx-chevron-up'></i>
                  </button>
                </div>
                <div class="nav-collapse-items">
                  <ul>
                    <li v-for="child in item.subItems" :key="child.id">
                      <a href="" @click.prevent="setActiveMenu(child)" :class="{'active':child.id === activeMenuId}"> {{ child.label }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="sidebar-bottom">
        <div class="bottom-menu__user" :class="{'bottom-menu__user_active': showLogout}">
          <div v-if="user" class="user">
            <div class="avatar-title rounded-circle">
              <i
                  v-if="!user.employee?.photo"
                  class="bx bx-user font-size-24"
              ></i>
              <img
                  v-else
                  :src="user.employee.photo"
                  class="cover-parent"
              />
            </div>
          </div>
          <p class="bottom-menu__user-name" @click="showLogout=!showLogout">
            <span >{{ user?.name }}</span>
            <span><i class="fas fa-angle-right"></i></span>
            <a href="#" v-show="showLogout" class="bottom-menu__logout" @click.prevent="$keycloak.logout()">
              Logout
              <i class="bx bx-power-off font-size-16 align-middle mr-1 text-danger" />
            </a>
            <span v-show="showLogout" class="bottom-menu__user-close"></span>
          </p>
        </div>
        <p class="bottom-menu__copy">{{ new Date().getFullYear() }} &copy; Bimiboo</p>
        <div class="bottom-menu__version">Version {{ version }}</div>
      </div>
    </div>
    <div class="sidebar-burger">
      <button @click.prevent="activeSidebar = !activeSidebar">
        <i class="fa fa-fw fa-bars">

        </i>
      </button>
    </div>
  </div>
</template>

<script>
import { menuItems } from "./menu";
import { mapState } from "vuex";

export default {
  name: "CustomSideBar",
  computed:{
    ...mapState('sidebar', {
      activeMenuId: (state) => {
        return state.menu.activeId
      }
    }),
    getMenuItems () {
      const result = this.menuItems.filter(item => this.$hasAccess(item.acl) || item.acl.includes('all'))
      return result.length > 0 ? result : []
    },
    user() {
      return this.$store.state.user.user;
    },
    getSizeForBottomMenu () {
      if (this.sidebarY < 731) {
        return (750 - 550) - 11 + 'px'
      }
      return (this.sidebarY - 550) - 11 + 'px'
    }
  },
  data() {
    return {
      menuItems: menuItems,
      showLogout: false,
      url: process.env.VUE_APP_AUTH_REDIRECT_LINK,
      activeSidebar: false,
      sidebarY: null,
      version: ''
    }
  },
  created() {
    const { buildTimestampUtc } = document.documentElement.dataset;
    if (buildTimestampUtc) {
      this.version = this.$moment(buildTimestampUtc).format('YYMMDD.HHmm')
    }
  },
  mounted() {
    this.findActiveElementAfterReloadPage (this.$route.path)
    this.resizeWindow()
  },
  beforeDestroy() {
    window.removeEventListener('resize', () => {
      return true
    })
  },
  methods: {
    resizeWindow () {
      this.sidebarY = document.getElementsByClassName('sidebar')[0].clientHeight
      window.addEventListener('resize', () => {
        this.sidebarY = document.getElementsByClassName('sidebar')[0].clientHeight
      })
    },
    setActiveMenu(item) {
      if (item.id === this.activeMenuId) {
        this.$store.commit('sidebar/SET_ACTIVE_MENU', null)
        return false
      }
      this.$store.commit('sidebar/SET_ACTIVE_MENU', item.id)
      if (item?.link && item.link !== this.$route.path) {
        this.$router.push(item.link)
      }

    },
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },
    findActiveElementAfterReloadPage(path){
      let id = null
      this.menuItems.forEach(item => {
        if (item.link === path) {
          id = item.id
        }
        if (item?.subItems) {
          item.subItems.forEach(child => {
            if (child.link === path) {
              id = child.id
            }
          })
        }
      })
      this.$store.commit('sidebar/SET_ACTIVE_MENU', id)
    },
    onClickOutside () {
      this.activeSidebar = false
    }
  }
}
</script>

<style scoped>

</style>
