<script>
import { layoutComputed } from "@/state/helpers";
import Vertical from "./vertical";

export default {
  components: {
    Vertical
  },
  data() {
    return {};
  },
  computed: {
    ...layoutComputed,
  },
  async mounted() {
    await this.$store.dispatch('settings/getSettingsList');
  }
};
</script>

<template>
  <div>
    <vertical
      :layout="layoutType"
    >
      <slot />
    </vertical>
  </div>
</template>

